import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { 
  Text, 
  Box, 
  Input, 
  Button, 
  Container,
  IconButton,
  VStack,
  HStack,
  Flex,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { FaPaperPlane, FaMicrophone, FaStop, FaBars } from 'react-icons/fa';
import config from '../config';

const { WEBSOCKET_URL, API_BASE_URL } = config;

const MessageBox = ({ isUser, children }) => (
  <Box 
    bg={isUser ? "blue.100" : "gray.100"}
    color={isUser ? "blue.800" : "gray.800"}
    borderRadius="md"
    p={2}
    mb={2}
    alignSelf={isUser ? "flex-end" : "flex-start"}
    maxWidth="80%"
  >
    {children}
  </Box>
);

const WelcomeOverlay = ({ isOpen, onClose, initialMessage, onStart }) => (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Welcome to the Simulation</ModalHeader>
        <ModalBody>
          <Text>{initialMessage}</Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={onStart}>Start Simulation</Button>
          </ModalFooter>
      </ModalContent>
    </Modal>
);

const SettingsModal = ({ isOpen, onClose, onOpenRestartConfirm, onOpenGoBackConfirm }) => (
  <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Simulation Menu</ModalHeader>
      <ModalBody>
        <VStack spacing={4}>
          <Button colorScheme="red" onClick={onOpenRestartConfirm} width="100%">
            Restart Simulation
          </Button>
          <Button colorScheme="gray" onClick={onOpenGoBackConfirm} width="100%">
            Go Back to Scenarios
          </Button>
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

const SimulationInterface = ({ scenario }) => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isSimulationEnded, setIsSimulationEnded] = useState(false);
    const [isEndingSimulation, setIsEndingSimulation] = useState(false);
    const [sessionId, setSessionId] = useState(() => uuidv4());
    const [isRecording, setIsRecording] = useState(false);
    const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
    const [initialMessage, setInitialMessage] = useState('');
    const [isOverlayOpen, setIsOverlayOpen] = useState(true);
    const [feedback, setFeedback] = useState(null);
    const socketRef = useRef(null);
    const audioRef = useRef(null);
    const messagesEndRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const navigate = useNavigate();
    const toast = useToast();
    const { user } = useAuth();
    const { scenarioName } = useParams();

    // For Settings Modal
    const { isOpen: isSettingsModalOpen, onOpen: onOpenSettingsModal, onClose: onCloseSettingsModal } = useDisclosure();

    // For Restart Confirmation Dialog
    const { isOpen: isRestartConfirmOpen, onOpen: onOpenRestartConfirm, onClose: onCloseRestartConfirm } = useDisclosure();
    const cancelRefRestart = useRef();

    // For Go Back Confirmation Dialog
    const { isOpen: isGoBackConfirmOpen, onOpen: onOpenGoBackConfirm, onClose: onCloseGoBackConfirm } = useDisclosure();
    const cancelRefGoBack = useRef();

    useEffect(() => {
        console.log('Initial sessionId:', sessionId);
        setupSocket(sessionId);
        setupSimulation(); 
        
        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
            }
            console.log('Component unmounting, isSimulationEnded:', isSimulationEnded);
        };
    }, [sessionId]); 

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    const setupSocket = (newSessionId) => {
        socketRef.current = io(WEBSOCKET_URL, { 
            transports: ['websocket'],
            reconnection: true,
            reconnectionAttempts: 5,
            reconnectionDelay: 1000,
        });

        socketRef.current.on('connect', () => {
            console.log('Socket connected')
        });
        socketRef.current.on('new_message', handleNewMessage);
        socketRef.current.on('simulation_ended', handleNormalEnding);
        socketRef.current.on('new_audio_ready', (data) => playAudio(data.audioPath));

    };

    const setupSimulation = async () => {
        console.log('Setting up simulation with sessionId:', sessionId, 'and scenario:', scenarioName);
    
        if (!scenarioName) {
            console.error('No scenario name provided');
            toast({
                title: "Error",
                description: "No scenario selected. Please choose a scenario and try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }
    
        if (!sessionId) {
            console.error('No sessionId available');
            const newSessionId = uuidv4();
            console.log('Generated new sessionId:', newSessionId);
            setSessionId(newSessionId);
            // Return early and let the useEffect trigger the API call
            return;
        }
    
        try {
            const response = await axios.post(`${API_BASE_URL}/api/setup_simulation`, { 
                scenario: scenarioName, 
                sessionId 
            });
            console.log('Setup simulation response:', response.data);  // Add this line for debugging
            setInitialMessage(response.data.initialMessage);
        } catch (error) {
            console.error('Error setting up simulation:', error.response?.data || error.message);
            toast({
                title: "Error",
                description: error.response?.data?.detail || "Failed to set up simulation. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            navigate('/scenarios');
        }
    };

    const startSimulation = async () => {
        console.log('Starting simulation with sessionId:', sessionId);
        try {
            const response = await axios.post(`${API_BASE_URL}/api/start_simulation`, { 
                sessionId 
            });
            console.log('Start simulation response:', response.data);
            // No need to process firstMessage here; it will be received via socket.io
        } catch (error) {
            console.error('Error starting simulation:', error.response?.data || error.message);
            toast({
                title: "Error",
                description: error.response?.data?.detail || "Failed to start simulation. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            navigate('/scenarios');
        }
    };
    
    const handleNormalEnding = async () => {
        console.log('Ending simulation normally...');
        setIsEndingSimulation(true);
        try {
            const response = await axios.post(`${API_BASE_URL}/api/end_simulation_normal`, { sessionId });
            setFeedback(response.data.feedback);
            setIsSimulationEnded(true);
            console.log("Simulation ended normally, feedback received");
        } catch (error) {
            console.error('Error ending simulation normally:', error);
            toast({
                title: "Error",
                description: "Failed to end simulation. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsEndingSimulation(false);
        }
    };

    const handleAnomalyEnding = async () => {
        if (isSimulationEnded) return;
        try {
            await axios.post(`${API_BASE_URL}/api/end_simulation_anomaly`, { sessionId });
        } catch (error) {
            console.error('Error ending simulation (anomaly):', error);
        }
    };

    const handleStartButtonClick = async () => {
        setIsOverlayOpen(false);
        await startSimulation();
    };    
    
    const handleGoToResult = () => {
        if (isSimulationEnded && feedback) {
            console.log('Navigating to result page with sessionId:', sessionId);
            navigate(`/simulation-result/${sessionId}`, { state: { feedback } });
        } else {
            console.log('Cannot go to result: simulation not ended or feedback not available');
        }
    };
    
    const playAudio = (audioPath) => {
                if (audioRef.current) {
            const fileName = audioPath.split('/').pop();
            audioRef.current.src = `${API_BASE_URL}/api/audio/${fileName}`;
            audioRef.current.play().catch(e => console.error('Error playing audio:', e));
        }
    };

    const handleNewMessage = (message) => {
        if (!message.isUser) {
            setMessages(prev => [...prev, message]);
        }
    };

    const handleTextSubmit = async (e) => {
        e.preventDefault();
        if (input.trim() && !isWaitingForResponse && !isSimulationEnded) {
            const userMessage = { sender: 'You', content: input.trim(), isUser: true };
            setMessages(prev => [...prev, userMessage]);
            await sendMessageToServer(input.trim());
            setInput('');
        }
    };

    const sendMessageToServer = async (message) => {
        setIsWaitingForResponse(true);
        try {
            await axios.post(`${API_BASE_URL}/api/send_message`, {
                sessionId: sessionId,
                message: message,
            });
        } catch (error) {
            console.error('Error sending message:', error);
            toast({
                title: "Error",
                description: "Failed to send message. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsWaitingForResponse(false);
        }
    };

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorderRef.current = new MediaRecorder(stream);
            const chunks = [];

            mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    chunks.push(event.data);
                }
            };

            mediaRecorderRef.current.onstop = () => {
                const blob = new Blob(chunks, { type: 'audio/wav' });
                                sendAudioToServer(blob);
            };

            mediaRecorderRef.current.start();
            setIsRecording(true);
        } catch (error) {
            console.error('Error starting recording:', error);
            toast({
                title: "Error",
                description: "Failed to start recording. Please check your microphone permissions.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current && isRecording) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
        }
    };

    const sendAudioToServer = async (blob) => {
        const formData = new FormData();
        formData.append('audio', blob, 'audio.wav');
        formData.append('sessionId', sessionId);
        formData.append('userId', user?.sub);

        try {
            const response = await axios.post(`${API_BASE_URL}/api/transcribe_audio`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
    if (response.data.text && response.data.text.trim() !== '') {
                const transcribedText = response.data.text.trim();
                const userMessage = { sender: 'You', content: transcribedText, isUser: true };
                setMessages(prev => [...prev, userMessage]);
                await sendMessageToServer(transcribedText);
    } else {
      toast({
        title: "Transcription Error",
        description: "No speech detected. Please try again.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    }
        } catch (error) {
            console.error('Error sending audio to server:', error);
            toast({
                title: "Error",
      description: error.response?.data?.detail || "Failed to process audio. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    // Function to handle Restart after confirmation
    const confirmRestart = async () => {
        onCloseRestartConfirm();      // Close the confirmation dialog
        onCloseSettingsModal();       // Close the settings modal
        await handleAnomalyEnding();  // End the current simulation
        await handleRestart();        // Restart the simulation
    };

    // Function to handle Go Back after confirmation
    const confirmGoBack = async () => {
        onCloseGoBackConfirm();       // Close the confirmation dialog
        onCloseSettingsModal();       // Close the settings modal
        await handleAnomalyEnding();  // End the current simulation
        handleGoBack();               // Navigate back to scenarios
    };

    // Function to restart the simulation
    const handleRestart = async () => {
        // Reset state variables
        setMessages([]);
        setInput('');
        setIsSimulationEnded(false);
        setFeedback(null);
        setInitialMessage('');
        setSessionId(uuidv4()); // Generate a new sessionId
        setIsOverlayOpen(true); // Re-open the WelcomeOverlay
    };

    // Function to navigate back to scenarios
    const handleGoBack = () => {
        navigate('/scenarios');
    };

    return (
        <Container maxW="container.md">
            {/* Welcome Overlay */}
            <WelcomeOverlay 
                isOpen={isOverlayOpen} 
                onClose={() => setIsOverlayOpen(false)}
                initialMessage={initialMessage}
                onStart={handleStartButtonClick}  
            />

            {/* Settings Modal */}
            <SettingsModal 
                isOpen={isSettingsModalOpen}
                onClose={onCloseSettingsModal}
                onOpenRestartConfirm={onOpenRestartConfirm}
                onOpenGoBackConfirm={onOpenGoBackConfirm}
            />

            {/* Confirmation Dialog for Restart */}
            <AlertDialog
                isOpen={isRestartConfirmOpen}
                leastDestructiveRef={cancelRefRestart}
                onClose={onCloseRestartConfirm}
                isCentered
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Restart Simulation
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to restart the simulation? This will end the current session.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRefRestart} onClick={onCloseRestartConfirm}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={confirmRestart} ml={3}>
                                Restart
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            {/* Confirmation Dialog for Go Back */}
            <AlertDialog
                isOpen={isGoBackConfirmOpen}
                leastDestructiveRef={cancelRefGoBack}
                onClose={onCloseGoBackConfirm}
                isCentered
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Go Back to Scenarios
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to go back to scenario selection? This will end the current simulation.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRefGoBack} onClick={onCloseGoBackConfirm}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={confirmGoBack} ml={3}>
                                Go Back
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            {/* Header with Title and Menu Button */}
            <Flex justify="space-between" align="center" my={4}>
                <Box>
                    <Text fontSize="2xl" fontWeight="bold" mb={2}>
                        Medical Simulation
                    </Text>
                    <Text>Scenario: {decodeURIComponent(scenarioName) || 'No scenario selected'}</Text>
                </Box>
                {/* Menu Button */}
                <IconButton
                    icon={<FaBars />}
                    aria-label="Menu"
                    onClick={onOpenSettingsModal}
                />
            </Flex>

            {/* Initial Message Overlay */}
            {!isOverlayOpen && initialMessage && (
                <Box bg="blue.50" p={4} borderRadius="md" mb={4}>
                    <Text fontWeight="bold">{initialMessage}</Text>
                </Box>
            )}

            {/* Messages Display */}
            <VStack 
                spacing={4} 
                align="stretch" 
                bg="gray.50" 
                p={4} 
                borderRadius="md" 
                height="400px" 
                overflowY="auto" 
                mb={4}
            >
                {messages.map((msg, index) => (
                    <MessageBox key={index} isUser={msg.isUser}>
                        <Text fontWeight="bold">{msg.sender}:</Text> {msg.content}
                    </MessageBox>
                ))}
                <Box ref={messagesEndRef} />
            </VStack>

            {/* Input Section */}
            <HStack as="form" onSubmit={handleTextSubmit}>
                <Input 
                    value={input} 
                    onChange={(e) => setInput(e.target.value)} 
                    placeholder="Type your message..."
                    isDisabled={isSimulationEnded || isRecording}
                />
                <IconButton 
                    icon={isRecording ? <FaStop /> : <FaMicrophone />}
                    colorScheme={isRecording ? "red" : "blue"}
                    onClick={isRecording ? stopRecording : startRecording}
                    isDisabled={isSimulationEnded}
                    aria-label={isRecording ? "Stop recording" : "Start recording"}
                />
                <IconButton 
                    icon={<FaPaperPlane />}
                    colorScheme="blue"
                    onClick={handleTextSubmit}
                    isDisabled={isSimulationEnded || isRecording || !input}
                    type="submit"
                    aria-label="Send message"
                />
            </HStack>

            {/* Ending Simulation Spinner */}
            {isEndingSimulation && (
                <Flex justify="center" mt={4}>
                    <Spinner />
                    <Text ml={2}>Ending simulation...</Text>
                </Flex>
            )}

            {/* Go to Result Button */}
            {isSimulationEnded && (
                <Flex justify="center" mt={4}>
                    <Button 
                        colorScheme="blue"
                        onClick={handleGoToResult}
                        isDisabled={!feedback}
                    >
                        Go to Result
                    </Button>
                </Flex>
            )}

            {/* Hidden Audio Element */}
            <audio ref={audioRef} style={{ display: 'none' }} />
        </Container>
    );
};
export default SimulationInterface;
