import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Text, SimpleGrid, VStack } from '@chakra-ui/react';
import { useAuth } from '../contexts/AuthContext';

const Dashboard = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const displayName = user?.display_name || 'User';

  const handleNavigation = (path) => {
      navigate(path);
  };

  return (
    <Box>
      <Container maxW="container.xl" pt={8}>
        <Text fontSize="2xl" fontWeight="bold" mb={6}>
          Welcome to OsceCoach, {displayName}!
        </Text>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
          {[
            { title: 'Start New Simulation', description: 'Choose a scenario to practice your communication skills', action: 'Choose Scenario', path: '/scenarios' },
            { title: 'My Progress', description: 'View your performance and improvement', action: 'View Progress', path: '/progress' },
            { title: 'Recent Simulations', description: 'Review your latest practice sessions', action: 'View History', path: '/history' },
            { title: 'Build Scenarios', description: 'Create, edit, and view, scenarios ', action: 'Edit Scenario', path: '/scenario_edit' },

          ].map((item, index) => (
            <VStack
              key={index}
              bg="white"
              boxShadow="md"
              borderRadius="md"
              p={6}
              spacing={4}
              align="stretch"
            >
              <Text fontSize="xl" fontWeight="semibold">{item.title}</Text>
              <Text>{item.description}</Text>
              <Button colorScheme="blue" onClick={() => handleNavigation(item.path)}>
                {item.action}
              </Button>
            </VStack>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default Dashboard;