import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardFooter, Text, Grid, Spinner, Box, Heading, Container } from '@chakra-ui/react';
import Layout from './Layout';
import config from '../config';

function ScenarioSelection() {
  const [scenarios, setScenarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    console.log('ScenarioSelection: Fetching scenarios');
    setLoading(true);
    fetch(`${config.API_BASE_URL}/api/scenarios`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('ScenarioSelection: Received scenarios:', data);
        setScenarios(data);
        setLoading(false);
      })
      .catch(e => {
        console.error('ScenarioSelection: Error fetching scenarios:', e);
        setError(e.message);
        setLoading(false);
      });
  }, []);

  const handleScenarioSelect = (scenario) => {
    console.log('ScenarioSelection: Selected scenario', scenario);
    navigate(`/simulation/${encodeURIComponent(scenario.name)}`);
  };

  if (loading) {
    return (
      <Layout>
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <Spinner size="xl" />
        </Box>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <Text color="red.500">Error: {error}</Text>
      </Layout>
    );
  }

  return (
    <Layout>
      <Container maxW="container.xl">
        <Heading as="h2" size="xl" mb={6}>Select a Scenario</Heading>
        <Grid templateColumns="repeat(auto-fill, minmax(250px, 1fr))" gap={6}>
        {scenarios.map((scenario, index) => (
            <Card key={index}>
              <CardBody>
                <Heading as="h3" size="md" mb={2}>{scenario.name}</Heading>
                <Text>{scenario.description}</Text>
              </CardBody>
              <CardFooter>
                <Button colorScheme="blue" onClick={() => handleScenarioSelect(scenario)}>
                  Choose Scenario
                </Button>
              </CardFooter>
            </Card>
        ))}
      </Grid>
      </Container>
    </Layout>
  );
};

export default ScenarioSelection;