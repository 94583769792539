import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ChakraProvider, CSSReset, Spinner, Box } from '@chakra-ui/react';
import { AuthProvider, useAuth } from './contexts/AuthContext';

import NavBar from './components/NavBar';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import SignUp from './components/SignUp';
import ConfirmSignUp from './components/ConfirmSignUp';
import Profile from './components/Profile';
import PrivateRoute from './components/PrivateRoute';
import ScenarioSelection from './components/ScenarioSelection';
import SimulationInterface from './components/SimulationInterface';
import SimulationResult from './components/SimulationResult';
import ScenarioReader from './components/ScenarioManagement/ScenarioReader';
import ScenarioBuilder from './components/ScenarioManagement/ScenarioBuilder';

function AppRoutes() {
  const { isAuthenticated, isLoading, checkAuthStatus } = useAuth();

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Routes>
      <Route path="/" element={isAuthenticated ? <Navigate to="/dashboard" replace /> : <Navigate to="/login" replace />} />
      <Route path="/login" element={isAuthenticated ? <Navigate to="/dashboard" replace /> : <Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/confirm-signup" element={<ConfirmSignUp />} />
      <Route path="/dashboard" element={<PrivateRoute><NavBar /><Dashboard /></PrivateRoute>} />
      <Route path="/profile" element={<PrivateRoute><NavBar /><Profile /></PrivateRoute>} />
      <Route path="/scenarios" element={<PrivateRoute><NavBar /><ScenarioSelection /></PrivateRoute>} /> 
      <Route path="/scenario_edit" element={<PrivateRoute><NavBar /><ScenarioReader /></PrivateRoute>} /> 
      <Route path="/simulation/:scenarioName" element={<PrivateRoute><SimulationInterface /></PrivateRoute>} /> 
      <Route path="/simulation-result/:sessionId" element={<PrivateRoute><NavBar /><SimulationResult /></PrivateRoute>} />
      <Route path="/scenario-builder/:scenarioName" element={<PrivateRoute><NavBar /><ScenarioBuilder /></PrivateRoute>} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

function App() {
  return (
    <ChakraProvider>
      <CSSReset />
      <AuthProvider>
        <Router>
          <AppRoutes />
        </Router>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
